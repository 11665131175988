import React, { Suspense, lazy, useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from './helpers/state';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container);

// Lazy load the i18n initialization
const loadI18n = () => import('./i18n');

const LazyBootstrap = lazy(() => import('./helpers/bootstrap'));

const LoadingFallback = () => (
  <div id="MainArea" autoscroll="true">
    <section id="content-wrapper">
      <div className="centre">
        <section id="preloader">
          <div className="spinner"></div>
        </section>
      </div>
    </section>
  </div>
)
  
const App = () => {
  const [i18nInstance, setI18nInstance] = useState(null);

  useEffect(() => {
    
    loadI18n().then(module => {
      setI18nInstance(module.default);
    });

  }, []);

  if (!i18nInstance) {
    return <LoadingFallback />;
  }

  return (
    <I18nextProvider i18n={i18nInstance}>
      <Provider>
        <Suspense fallback={<LoadingFallback />}>
          <LazyBootstrap />
        </Suspense>
      </Provider>
    </I18nextProvider>
  );
};

root.render(

  <React.StrictMode>
    <Suspense fallback={<LoadingFallback />}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Suspense>
  </React.StrictMode>
);